<!--
 * @Description: 首页组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div class="carousel">
      <div class="carousel-left">
        <ul style="overflow: hidden;">
          <li class="carousel-hover1 flexspb" v-for="(item, index) in goodsClassList" :key="index">

            <!-- <router-link to="/goods">  -->
            <a :href="'#/goods?categoryID=' + item.Id" class="flexal">{{ item.Title }} &nbsp; | &nbsp; <div
                style="width: 80px;height: 16px;margin-left: 4px;" class="text-1"><span
                  style="font-size: 14px;margin-right: 0;float: left;" class="">{{ item.Intro }}</span>
              </div></a><span>></span>
            <!-- </router-link> -->
            <div class="carousel1-hover">
              <ul style="display: flex;flex-wrap: wrap;">
                <li v-for="(good, gindex) in item.ChildrenList" :key="gindex">
                  <img :src="getUrl(good.ImgUrl)" alt="" />
                  <router-link :to="{
                    path: '/goods',
                    query: { categoryID: item.Id },
                  }">
                    {{ good.Title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="swiper mySwiper block" style="width: 1220px;">
        <el-carousel height="460px">
          <el-carousel-item v-for="(item, index) in carousel" :key="index">
            <img style="height:460px;width: 1220px;" :src="getUrl(item.ImgUrl)" :alt="item.Title" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="user">
        <div style="box-sizing: border-box;">
          <div class="flexal">
            <div class="avat flexcen" style="box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);">
              <div style="box-sizing: border-box;border-radius: 50%;width: 68px;height: 68px;" class="flexcen">
                <img src="../images/logo2.png" alt=""
                  v-if="(($store.getters.getUser.length == 0) || $store.getters.getUser.Avatar == '')" />
                <img :src="$store.getters.getUser.Avatar" alt="" v-else>
              </div>

            </div>
            <div class="userName" v-if="this.$store.getters.getUser.UserName != undefined">
              hi, {{ this.$store.getters.getUser.UserName }}
            </div>
            <div v-else>
              欢迎来到航鑫源商城
            </div>
          </div>
          <div class="two flexspb " v-if="($store.getters.getUser.length != 0)">
            <router-link to="/PersonalCenter/Personal">
              <div class="bots flexcen botss">会员中心</div>
            </router-link>

            <div class="bots flexcen bgc botss" v-on:click="logout">退出登录</div>
          </div>
          <div class="two flexcen flexspb" v-if="($store.getters.getUser.length == 0)">
            <router-link to="/login">
              <div class="bots flexcen botss">登录</div>
            </router-link>
            <router-link to="/login">
              <div class="bots flexcen botss">注册 </div>
            </router-link>
          </div>
        </div>
        <!-- <div class="twos flexspb">
          <router-link to="/PersonalCenter/Personal">
            <img src="../images/gerenxinxi.png" alt="" style="width: 90px;height: 90;">
          </router-link>
          <router-link to="/PersonalCenter/Address">
            <img src="../images/dizhi.png" alt="" style="width: 90px;height: 90;">
          </router-link>
        </div> -->
        <div style="width: 100%;height: 90px;" v-if="false">
          <div class="flexspb">
            <div style="font-size: 14px;
                color: #333;
                margin-top: 11px;
                font-weight: 600;">快讯</div>
            <div style="font-size: 12px;
              text-align: center;
              margin-top: 8px;
              color: #888;
              float: right;
              height: 26px;
              line-height: 26px;
              padding-right: 4px;">更多></div>
          </div>
        </div>
        <div class="twos flexspb" v-if="false">
          <div class="boxs flexcen flexcum">
            <img src="../images/browse.png" alt="" />
            浏览
          </div>
          <!-- <img src="../images/dingdan.png" alt="" style="width: 90px;height: 90;"> -->
          <div class="boxs flexcen flexcum">
            <img src="../images/favorite.png" alt="" />
            收藏
          </div>
          <router-link to="/PersonalCenter/Order">
            <div class="boxs flexcen flexcum">
              <img src="../images/order.png" alt="" />
              订单
            </div>
            <!-- <img src="../images/dingdan.png" alt="" style="width: 90px;height: 90;"> -->
          </router-link>
        </div>
        <div class="twos flexspb" v-if="false">
          <div class="boxs flexcen flexcum">
            <img src="../images/security.png" alt="" />
            账号安全
          </div>
          <!-- <img src="../images/dingdan.png" alt="" style="width: 90px;height: 90;"> -->
          <div class="boxs flexcen flexcum">
            <img src="../images/trade1.png" alt="" />
            余额
          </div>
          <router-link to="/Joinus/MerchantSettled">
            <div class="boxs flexcen flexcum">
              <img src="../images/shangjia.png" alt="" />
              商家入驻
            </div>
            <!-- <img src="../images/shangjiaruzhu.png" alt="" style="width: 90px;height: 90;"> -->
          </router-link>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->

    <!-- 轮播图END -->
    <div class="main-box" style="margin-top: 24px;width: 1440px;margin: 24px auto;background-color: #fff;">
      <div class="main " style="max-width: 1420px;">
        <div class="blocks">
          <div class="flexspb titls">
            <div class="les">精品推荐</div>
            <router-link to="/goods">
              <div class="rigs" style="cursor: pointer;">更多</div>
            </router-link>
          </div>
          <div class="flexspb" style="align-items: stretch;">
            <div class="lefts flexcum flexspb">
              <div class="classify">
                <div class="classList flexcen" v-for="(item, index) in  goodsClassList" :key="index"
                  @click="tempGOS(item.Id, index)" :class="tempIndex == index ? 'bgxs1' : ''">
                  <p>{{ item.Title }}</p>
                </div>

              </div>
            </div>
            <div>
              <div class="daohan flexal" v-if="goodsClassList[tempIndex]&&goodsClassList[tempIndex].ChildrenList!=null && goodsClassList[tempIndex].ChildrenList.length != 0">
                <div class="daohans flexcen" v-for="(good, gindex) in goodsClassList[tempIndex].ChildrenList" :key="gindex"
                 @click="tempGOS2(good.Id,gindex)" :class="gindex == tempIndex2?'gis':''">
                  {{ good.Title }}
                </div>
              </div>
              <div class="rigts">
                <div class="goodsList" v-for="(item, index) in  tempGoods" :key="index">
                  <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                    <img :src="getUrl(item.ImgUrl)" class="goodsImg" alt="">
                    <div class="goodsName">
                      {{ item.Name }}
                    </div>
                    <div class="goodsMon" style="color: #FF0000;font-size: 18px;padding-left: 11px;">
                      ￥ {{ item.Price }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blocks" v-if="false">
          <div class="flexspb titls">
            <div class="les">精品推荐</div>
            <router-link to="/goods">
              <div class="rigs" style="cursor: pointer;">更多</div>
            </router-link>

          </div>
          <div class="flexspb" style="align-items: stretch;">
            <div class="lefts flexcum flexspb" style="background: #9FC579;">
              <div class="classify">
                <div class="classList flexcen" v-for="(item, index) in  goodsClassList" :key="index"
                  @click="tempGOS(item.Id, index)" :class="tempIndex == index ? 'bgxs2' : ''">
                  <p>{{ item.Title }}</p>
                </div>

              </div>
            </div>
            <div class="rigts">
              <div class="goodsList" v-for="(item, index) in  tempGoods" :key="index">
                <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                  <img :src="getUrl(item.ImgUrl)" class="goodsImg" alt="">
                  <div class="goodsName">
                    {{ item.Name }}
                  </div>
                  <div class="goodsMon" style="color: #FF0000;font-size: 18px;padding-left: 11px;">
                    ￥ {{ item.Price }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="content GuessLike" style="margin-top: 24px;width: 1440px;margin: 24px auto;" v-if="false">
      <div class="title">
        <p>猜你喜欢</p>
      </div>
      <div class="GuessList">
        <el-carousel trigger="click" height="311px">
          <el-carousel-item v-for="(item, index) in likeLeng" :key="item">
            <div class="listbox">
              <div v-for="(item2, index2) in like[index]" :key="index2" class="product">
                <router-link :to="{ path: '/goods/details', query: { productID: item2.Id } }" class="product"
                  style="border:none;width:100%;height:100%;display:block">
                  <img :src="getUrl(item2.ImgUrl)" alt="" class="img">
                  <p class="productName">{{ item2.Name }}</p>
                  <p class="productPrice">￥{{ item2.Price }}</p>
                </router-link>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      carousel: "", // 轮播图数据
      phoneList: "", // 手机商品列表
      miTvList: "", // 小米电视商品列表
      applianceList: "", // 家电商品列表
      applianceHotList: "", //热门家电商品列表
      accessoryList: "", //配件商品列表
      accessoryHotList: "", //热门配件商品列表
      protectingShellList: "", // 保护套商品列表
      chargerList: "", //充电器商品列表
      applianceActive: 1, // 家电当前选中的商品分类
      accessoryActive: 1, // 配件当前选中的商品分类
      goodsClassList: [], //商品类型列表
      RedGoodsClassList: [], //推荐商品分类列表
      goodsLength: 0,
      newGood: [],
      GuessIndex: 0,
      newGoods: [],
      like: [],
      likeLeng: 0,
      tempGoods: [],
      tempIndex: 0,
      tempIndex2: -1
    };
  },
  created() {
    // 获取轮播图数据
    this.loadBannerList();
    this.loadGoodClassList();
    this.loadGoodClassListToRed();
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    async loadBannerList() {
      var res = await this.postdata("/News/BannerList", {
        page: 1,
        limit: 10,
        channelCode: "index",
      });
      if (res.code == 200) {
        this.carousel = res.data.items;
      }
    },
    async loadGoodClassList() {
      var res = await this.postdata("/News/ClassList", {
        "page": 1, //分页页码，数字类型
        "limit": 1000, //条数
        "queryState": 0, //状态 0-可用
        "queryType": 0, //类型 0-商品
        parentId: 0
      });
      if (res.code == 200) {
        this.goodsClassList = res.data.items;
        
        // this.goodsClassList.splice(0,0,{
        //   Title:'全部',
        //   Id:0
        // })
        this.tempGOS(0, 0)
      }
      // res = await this.postdata("/Goods/GoodsList", {
      //   "page": 1,
      //   "limit": 20,
      //   "IsHot": 0, //是否爆款
      //   "IsNew": 0, //是否新品
      //   "parentId": this.goodsClassList[0].Id //商品分类
      // });
      // if (res.code == 200) {
      //   this.tempGoods = res.data.items;
      // }
    },
    async tempGOS(id, index) {
      this.tempIndex = index
      this.tempIndex2 = -1
      var res = await this.postdata("/Goods/GoodsList", {
        "page": 1,
        "limit": 20,
        // "IsHot": 0, //是否爆款
        // "IsNew": 0, //是否新品
        "parentId": id != 0 ? id : '', //商品分类
        "queryType": 0
      });
      if (res.code == 200) {
        this.tempGoods = res.data.items;
      }
    },
    async tempGOS2(id, index) {
      this.tempIndex2 = index
      var res = await this.postdata("/Goods/GoodsList", {
        "page": 1,
        "limit": 20,
        // "IsHot": 0, //是否爆款
        // "IsNew": 0, //是否新品
        "parentId": id != 0 ? id : '' ,//商品分类
        "queryType": 0
      });
      if (res.code == 200) {
        this.tempGoods = res.data.items;
      }
    },
    async loadGoodClassListToRed() {
      // var res = await this.postdata("/Goods/GoodsList", {
      //   page: 1,
      //   limit: 1000,
      //   queryState: 0,
      //   IsTop: 1,
      // });
      // if (res.code == 200) {
      //   this.RedGoodsClassList = res.data.items;
      //   if (this.RedGoodsClassList.length > 4) {
      //     this.goodsLength = parseInt(this.RedGoodsClassList.length / 4);
      //     if (this.RedGoodsClassList.length % 4 == 0)
      //       this.goodsLength = this.RedGoodsClassList.length / 4;
      //     if (this.RedGoodsClassList.length % 4 != 0)
      //       this.goodsLength =
      //         parseInt(this.RedGoodsClassList.length / 4) + 1;
      //     this.newGood = this.sliceArray(this.RedGoodsClassList, 4);
      //   } else {
      //     this.goodsLength = 1
      //     this.newGood = this.sliceArray(this.RedGoodsClassList, 4);
      //   }
      //   console.log(this.newGood)
      // }
      // res = await this.postdata("/Goods/GoodsList", {
      //   page: 1,
      //   limit: 4,
      //   queryState: 0,
      //   IsNew: 1,
      // });
      // if (res.code == 200) {
      //   this.newGoods = res.data.items;

      // }
      // res = await this.postdata("/Goods/GoodsList", {
      //   "page": 1,
      //   "limit": 20,
      //   "IsStock": 1, //有货商品 1-是
      //   "orderBy": 0 //排序 0-综合 1-销量倒序 2-价格正序 3-价格倒序
      // });
      // if (res.code == 200) {
      //   let list = res.data.items;
      //   if (list.length > 4) {
      //     this.likeLeng = parseInt(list.length / 4);
      //     if (list.length % 4 == 0)
      //       this.likeLeng = list.length / 4;
      //     if (list.length % 4 != 0)
      //       this.likeLeng =
      //         parseInt(list.length / 4) + 1;
      //     this.like = this.sliceArray(list, 4);
      //   } else {
      //     this.likeLeng = 1
      //     this.like = this.sliceArray(list, 4);
      //   }
      // }
    },
    sliceArray(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("userToken", "");
      // 清空vuex登录信息
      this.setUser("");
      this.notifySucceed("成功退出登录");
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../assets/css/index.css";

.carousel {
  width: 1440px;
  margin: auto;
  position: relative;
  height: 100%;
  overflow: hidden;
  // padding-left: 25px;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 213px;
}

.carousel>.carousel-left {
  width: 213px;
  background-color: #252221;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 0;
  z-index: 10;
  height: 100%;
}

.carousel>.carousel-left .carousel-hover1 {
  list-style: none;
  width: 95%;
  padding: 10.5px 0px;
}

.carousel>.carousel-left .carousel-hover1:hover {
  background-color: #fff;
  cursor: pointer;
}

.carousel>.carousel-left .carousel-hover1:hover>a {
  color: #000;
}

.carousel>.carousel-left .carousel-hover1:hover>.carousel1-hover {
  display: block;
  box-shadow: 10px 10px 20px #7c7c7c;
}

.carousel>.carousel-left .carousel-hover1,
.carousel>.carousel-left .carousel-hover1 a {
  text-decoration: none;
  font-size: 14px;
  color: #ffff;
  margin-left: 12px;
}

.carousel>.carousel-left .carousel-hover1 span {
  display: block;
  float: right;
  // color: aliceblue;
  margin-right: 20px;
}

.carousel>.carousel-left .carousel-hover1 a:hover {
  color: #000 !important;
}

.carousel>.carousel-left .carousel1-hover {
  background-color: #ffffff;
  width: 986px;
  height: 456px;
  position: absolute;
  left: 213px;
  top: 0px;
  box-shadow: 20px -20px 20px #7c7c7c;
  display: none;
}

.carousel>.carousel-left .carousel1-hover li {
  list-style: none;
  // float: left;
  width: 240px;
  margin-top: 7px;
  margin-bottom: 20px;
}

.carousel>.carousel-left .carousel1-hover li>img {
  float: left;
  margin-left: 10px;
  width: 70px;
  max-height: 70px;
}

.carousel>.carousel-left .carousel1-hover li>a {
  color: #616161;
  text-decoration: none;
  line-height: 50px;
  float: left;
  width: 140px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.carousel>.carousel-left .carousel1-hover li>a:hover {
  color: #ff6700;
}

.el-carousel__arrow--left {
  left: 246px !important;
}

.box-hd {
  height: 58px;
  margin: 20px 0 0 0;
}

.box-hd .more {
  float: right;
}

.user {
  width: 255px;
  height: 127px;
  // background: #a88e77;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 6;
  padding: 14px 0;
  // background: url(../images/entrance.png) no-repeat;
  box-shadow: 0px 0px 10px 0px #cacaca;
  background-color: #fff;
}

.user .avat {
  border: 4px solid #fff;
  width: 68px;
  height: 68px;
  background: #fff;
  border-radius: 50%;
  margin: 0 18px;
}


.user .avat img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user .userName {
  font-size: 18px;
  text-align: center;
}

.user .two {
  width: 100%;
  padding: 0 16px;
  margin-top: 15px;
  box-sizing: border-box;
}

.user .two .bots {
  width: 102px;
  height: 28px;
  background: #ED5D29;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

}

.user .two .botss {
  box-shadow: 4px 6px 12px 2px rgba(44, 44, 44, 0.15);
}

// .user .two .bots:hover {
//   background-color: #f42424;
//   color: #fff;
// }

// .user .two .bgc {
//   color: #fff;
//   background: #f42424;
// }

.user .twos {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  // position: absolute;
  // bottom: 32px;
  // left: 0;
  margin-top: 15px;
}

.user .twos .boxs {
  width: 55px;
  height: 55px;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #fa5d59;
  font-size: 12px;
  cursor: pointer;
}

.user .twos .boxs img {
  margin-bottom: 6px;
  width: 25px;
  height: 25px;
}

/* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
} */

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
}

.blocks {
  width: 100%;
  /* height: 782px; */
  background: #ffffff;
  padding: 0 0 24px;
  box-sizing: border-box;
}

.blocks .titls {
  width: 100%;
  height: 88px;
}

.blocks .titls .les {
  font-size: 28px;
}

.blocks .titls .rigs {
  font-size: 18px;
  color: #333333;
}

.lefts {
  width: 262px;
  height: 695px;
  background: #ED5D29;
  overflow: hidden;
}

.classify {
  width: 100%;
  padding: 23px 0;
  display: flex;
  flex-wrap: wrap;
}

.classList {
  width: 101px;
  height: 37px;
  border-radius: 8px 8px 8px 8px;
  border: 2px solid #FFFFFF;
  color: #fff;
  font-size: 19px;
  margin-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;

}

.classList p {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.bgxs1 {
  background-color: #fff;
  border: 0;
  color: #ED5D29;
}

.bgxs2 {
  background-color: #fff;
  border: 0;
  color: #9FC579;
}

.rigts {
  width: 1102px;
  display: flex;
  flex-wrap: wrap;
}

.goodsList {
  width: 25%;
  height: 348px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  cursor: pointer;
}

.goodsImg {
  width: 100%;
  height: 235px;
}

.goodsName {
  font-size: 18px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  padding: 10px 14px;
  height: 57px;
  box-sizing: border-box;
}

.goods {
  width: 100%;
  height: 120px;
  margin-bottom: 32px;
}

.goods img {
  width: 120px;
  height: 120px;
  margin-right: 21px;
}

.goods .cents {
  height: 100%;
  flex: 1;
}

.goods .cents .goodsName {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 18px;
  margin-bottom: 28px;
}

.goods .cents .money {
  font-size: 24px;
  color: #e83f00;
  text-align: right;
  width: 100%;
}

.el-carousel__container {
  height: 700px !important;
}

.goods2 {
  width: 100%;
  padding: 0 52px 52px;
  display: flex;
  flex-wrap: wrap;
}

.goods2 .goodCen {
  margin-right: 60px;
  margin-bottom: 24px;
  width: 220px;
  cursor: pointer;
}

.goods2 .goodCen img {
  width: 220px;
  height: 220px;
  margin-bottom: 16px;
}

.goods2 .goodCen .text {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 18px;
}

.goods2 .nor {
  margin-right: 0;
}

//猜你喜欢
.GuessLike {
  margin: 24px auto 0 auto !important;

  .title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: 600;
  }

  .GuessList {
    .listbox {
      display: flex;
      margin: 1px;

      .product {
        margin: -1px 0 0 -1px;
        border: 1px dashed #cacaca;
        overflow: hidden;
        text-align: center;
        padding-bottom: 16px;
      }

      .img {
        margin: 12px 0 0 0;
        width: 169px;
        height: 169px;
      }

      .productName {
        font-size: 18px;
        line-height: 36px;
        margin-top: 10px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //2行
        overflow: hidden;
        word-break: break-all;
        padding: 0 20px;
      }

      .productPrice {
        font-size: 18px;
        color: #E83F00;
      }

      .product {
        width: 25%;
      }

      .gwcbtn {
        margin: 16px 0 16px 0;
        display: inline-block;
        height: 28px;
        line-height: 28px;
        padding: 0 20px;
        border: 1px solid #999;
        color: #666;
        cursor: pointer;
        text-align: center;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
    }
  }
}

.daohan {
  width: 100%;
  height: 41px;
  margin-bottom: 16px;
}

.daohans {
  height: 26px;
  max-width: 100px;
  overflow: hidden;
  margin: 7px 10px 0 0;
  margin-bottom: 8px;
  padding: 0 10px;
  background: #fff;
  font-size: 14px;
  border: 1px solid#ED5D29;
  color: #ED5D29;
  border-radius: 6px;
  cursor: pointer;
}
.gis{
  border: 0;
  background: #ED5D29;
  color: #fff;
}
</style>